import { Component, Input } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html'
})
export class HeadingComponent {
  @Input() title = '';
  @Input() callbackFunction: Function; 
  path = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location) {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      })
    ).subscribe((event) => {
     this.path = this.router.url.split('?')[0];
    });
  }

  getLabel(path): string {
    if (path === environment.adminRoot) {
      return 'menu.home';
    }

    var address = path.replace('/app/', '');   
    var array = address.split('/');
    address = '';
    for (var i = 0; i < array.length; i++) {
      if (i > 0)
        address += ' - ';

      address += array[i].charAt(0).toUpperCase() + array[i].slice(1);
    }

    const search = '.';
    const replaceWith = ' ';

    var names = address.split(search);
    for (var i = 0; i < names.length; i++) 
      names[i] = names[i].charAt(0).toUpperCase() + names[i].slice(1);
    

    const result = names.join(replaceWith);

    return result;
    
  }

  refresh(): void {
    this.callbackFunction();
  }

  back(): void {
    this.location.back();
  }

}
