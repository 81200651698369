import { Component, ViewChild, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TokenService } from 'src/app/shared/token.service'
import { Helpers } from 'src/app/shared/helpers';
import { Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') loginForm: NgForm;
  emailModel = '';
  passwordModel = '';

  buttonDisabled = false;
  buttonState = '';


  constructor(private helpers: Helpers,
    private router: Router,
    private tokenService: TokenService,
    private notifications: NotificationsService,
    private renderer: Renderer2,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'background');
    this.renderer.addClass(document.body, 'no-footer');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'background');
    this.renderer.removeClass(document.body, 'no-footer');
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      if (!this.buttonDisabled) {

        this.buttonDisabled = true;
        this.buttonState = 'show-spinner';        
        
        this.tokenService.login(this.loginForm.value).subscribe((user:User ) => {
          if (user.statusLogin > 0) {
            this.buttonDisabled = false;
            this.buttonState = '';
            this.notifications.create('Atenção: ', user.ds_Mensagem, NotificationType.Bare, {
              theClass: 'outline primary',
              timeOut: 6000,
              showProgressBar: false
            });
          }
          else {
            this.helpers.setUser(user);
            this.tokenService.setToken(user.ds_Token);
            

            if (user.cd_Dashboard)
              this.router.navigate(['/app/dashboards/' + user.cd_Dashboard]);
            else
              this.router.navigate(['/app/dashboards/default']);

           
          }
        },        
          (error) => {            
            this.buttonDisabled = false;
            this.buttonState = '';
            this.notifications.create('Atenção: ', error, NotificationType.Bare, {
              theClass: 'outline primary',
              timeOut: 6000,
              showProgressBar: false
            });

        });
      }
    }

  }
}
