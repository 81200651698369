import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AppConfig } from 'src/app/config/app.config';

@Injectable()

export class Helpers {

  public pathAPI: string;

  private authenticationChanged = new Subject<boolean>();
  constructor(public config: AppConfig, public http: HttpClient, public router: Router) {    
    this.pathAPI = this.config.setting['PathAPI'];
  }

  public isAuthenticated(): boolean {
    return (!(window.localStorage['token'] === undefined ||
      window.localStorage['token'] === null ||
      window.localStorage['token'] === 'null' ||
      window.localStorage['token'] === 'undefined' ||
      window.localStorage['token'] === ''));
  }
  public isAuthenticationChanged(): any {
    return this.authenticationChanged.asObservable();
  }
  public getToken(): any {
    if (window.localStorage['token'] === undefined ||
      window.localStorage['token'] === null ||
      window.localStorage['token'] === 'null' ||
      window.localStorage['token'] === 'undefined' ||
      window.localStorage['token'] === '') {
      return '';
    }
    let obj = JSON.parse(window.localStorage['token']);
    return obj;
  }
  public setToken(data: any): void {
    this.setStorageToken(JSON.stringify(data));
  }
  public failToken(): void {
    this.setStorageToken(undefined);
  }
  public logout(): void {
    this.setStorageToken(undefined);
  }
  private setStorageToken(value: any): void {
    window.localStorage['token'] = value;
    //this.authenticationChanged.next(this.isAuthenticated());
  }

  public setUser(data: any): void {
    //this.auth.updateCurrentUser(data);
    this.setStorageUser(JSON.stringify(data));
  }

  private setStorageUser(value: any): void {
    window.localStorage['user'] = value;
  }

  public getUser(): any {
    if (window.localStorage['user'] === undefined ||
      window.localStorage['user'] === null ||
      window.localStorage['user'] === 'null' ||
      window.localStorage['user'] === 'undefined' ||
      window.localStorage['user'] === '') {
      return '';
    }
    let obj = JSON.parse(window.localStorage['user']);
    return obj;
  }

  public handleError(error: Response | any, _router: Router) {
    console.log(error);

    if (error.status == 401) {
      _router.navigate(['/user/login']);
    }

    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    }
    else if (error instanceof HttpErrorResponse) {
      if (error.error)
        errMsg = error.error.text;
    }
    else {
      errMsg = error.message ? error.message : error.toString();
    }
    
    return throwError(errMsg);  //Observable.throw(errMsg);
  }

  public header(isBlob: boolean) {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('Access-Control-Allow-Origin', '*');
    header = header.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    header = header.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    header = header.append('Access-Control-Allow-Credentials', 'true');

    if (isBlob) {
      header = header.append('responseType', 'blob');
      header.delete('Content-Type', 'application/json');
      header = header.append('Content-Type', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    if (this.isAuthenticated()) {
      header = header.append('Authorization', 'Bearer ' + this.getToken());
    }
    return { headers: header };
  }

  public post<T>(url: string, data: any, retryNumber: number) {
    return this.http.post<T>(
      this.pathAPI + url,
      JSON.stringify(data),
      this.header(false)
    )
      .pipe(retry(retryNumber), catchError(err => this.handleError(err, this.router)));
  }

  public postBlob<T>(url: string, data: any, retryNumber: number): Observable<any>  {


    var headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    headers = headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    headers = headers.append('Access-Control-Allow-Credentials', 'true');
    headers = headers.append('ResponseType', 'blob');    
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.getToken());

   
     

    return this.http.post<T>(
      this.pathAPI + url,
      JSON.stringify(data),      
      
      { responseType: 'blob' as 'json', headers: headers } 
      ,      
    )
      .pipe(retry(retryNumber), catchError(err => this.handleError(err, this.router)));
  }

  public get<T>(url: string, retryNumber: number) {
    return this.http.get<T>(
      this.pathAPI + url,
      this.header(false)
    )
      .pipe(retry(retryNumber), catchError(err => this.handleError(err, this.router)));
  }

  public postFill<T>(url: string, data: any, retryNumber: number, collection: T) {
    return this.http.post<T>(this.pathAPI + url, data, this.header(false)).pipe(
      tap(data => collection = data));
  }

  public getFill<T>(url: string, retryNumber: number, collection: T) {
    return this.http.get<T>(this.pathAPI + url, this.header(false)).pipe(
      tap(data => collection = data));
  }
}
