import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { TokenService } from './shared/token.service';
import { Helpers } from './shared/helpers';
import { AppConfig } from './config/app.config';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications';
import { PopoverModule, PopoverConfig } from 'ngx-bootstrap/popover';
import { PeriodComponent } from './components/filters/period/period.component';
import { EmissorComponent } from './components/filters/emissor/emissor.component';
import { PagerComponent } from './components/filters/pager/pager.component';
import { Filial } from 'src/app/shared/Filial'; 

registerLocaleData(ptBr);
@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase)
  ],
  declarations: [
    AppComponent,
    PagerComponent
  ],
  providers: [
    AppConfig,    
    NotificationsService,    
    Helpers,
    TokenService,
    Filial,
    PopoverModule,
    PopoverConfig,
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
