<div class="fixed-background"></div>
<main>
  <div class="container">
    <div class="row h-100">
      <div class="col-12 col-md-10 mx-auto my-auto">
        <div class="card auth-card">
          <div class="position-relative image-side ">
            <p class=" text-white h2">EASY ERP</p>
            <p class="white mb-0">
              Making managing your business <strong>Easy</strong>
            </p>
          </div>
          <div class="form-side">
            <a class="c-pointer">
              <span class="logo-single"></span>
            </a>
            <h6 class="mb-4">Acesso ao Sistema</h6>

            <form #loginForm="ngForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
              <label class="form-group has-float-label">
                <input class="form-control" type="number" maxlength="11" required ngModel #email="ngModel" name="login" [(ngModel)]="emailModel" />
                <span>{{ 'user.email' | translate }}</span>
                <div *ngIf="!email.valid && loginForm.submitted" class="invalid-tooltip">Informe o login</div>
              </label>
              <label class="form-group has-float-label">
                <input class="form-control" type="password" placeholder="" required ngModel #password="ngModel" name="password"
                       [(ngModel)]="passwordModel" />
                <span>{{ 'user.password' | translate }}</span>
                <div *ngIf="!password.valid && loginForm.submitted" class="invalid-tooltip">Informe a senha!</div>
              </label>
              <div class="d-flex justify-content-between align-items-center">
                <a routerLink="/user/forgot-password">{{ 'user.forgot-password' | translate }}?</a>
                <app-state-button [btnClass]="'btn btn-primary btn-lg btn-shadow'" [currentState]="buttonState"
                                  [isDisabled]="buttonDisabled" >
                  {{ 'user.login-button' | translate | uppercase }}
                </app-state-button>
              </div>
            </form>

          </div>
        </div>
        <simple-notifications></simple-notifications>

      </div>
    </div>
  </div>
</main>
