<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <div class="search" (click)="searchAreaClick($event)">
      <input placeholder="Pesquisar..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div>

    <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
     
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item"
            href="javascript:;">{{item.label}}</a></li>
      </ul>
    </div>

   
  </div>

  <a class="navbar-logo" [routerLink]="adminRoot" >
    <span class="logo-cliente d-none d-xs-block"></span>
    <span class="logo-cliente-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div class="d-none d-md-inline-block align-text-bottom mr-1">
     
      </div>

      

    </div>

    <div class="user d-inline-block">
      <span class="logo d-none d-xs-block"></span>
      <span class="logo-mobile d-block d-xs-none"></span>
    </div>

  </div>
</nav>
