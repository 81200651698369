import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { of } from 'rxjs';
import { retry, catchError, map, tap } from 'rxjs/operators';
import { Helpers } from 'src/app/shared/helpers';
import { AppConfig } from 'src/app/config/app.config';

@Injectable()
export class BaseService {

  protected helper: Helpers;
  protected config: AppConfig;
  protected http: HttpClient;
  public pathAPI: string;

  constructor(_helper: Helpers, _config: AppConfig, _http: HttpClient) {

    this.helper = _helper;
    this.config = _config;
    this.http = _http;

    this.pathAPI = this.config.setting['PathAPI'];
  }

  public post<T>(url: string, data: any, retryNumber: number) {
      return this.http.post<T>(
        this.pathAPI + url,
        JSON.stringify(data),
        this.header()
      )
        .pipe(retry(retryNumber), catchError(this.handleError));
  }

  public get<T>(url: string, retryNumber: number) {
    return this.http.get<T>(
      this.pathAPI + url,
      this.header()
    )
      .pipe(retry(retryNumber), catchError(this.handleError));
  }

  public extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  public handleError(error: Response | any) {
    console.log(error);
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    }
    else if (error instanceof HttpErrorResponse) {
      if (error.error)
        errMsg = error.error.text;
    }
    else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.log(error);
    return throwError(errMsg);  //Observable.throw(errMsg);
  }


  public header() {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('Access-Control-Allow-Origin', '*');
    header = header.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    header = header.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    header = header.append('Access-Control-Allow-Credentials', 'true');

    if (this.helper.isAuthenticated()) {
      header = header.append('Authorization', 'Bearer ' + this.helper.getToken());
    }
    return { headers: header };
  }


  public setToken(data: any) {
    this.helper.setToken(data);
  }


  public failToken(error: Response | any) {
    this.helper.failToken();
    return this.handleError(Response);
  }
}
