import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { retry, catchError, map, tap } from 'rxjs/operators';
import { AppConfig } from 'src/app/config/app.config';
import { BaseService } from './base.service';
//import { Token } from '../models/token';
import { Helpers } from 'src/app/shared/helpers';
import { User } from 'src/app/shared/user';

@Injectable()
export class TokenService extends BaseService {
 
  public errorMessage: string;

  constructor(public helper: Helpers, public config: AppConfig, public http: HttpClient) {
    super(helper, config, http);
  }
  

  public login(data: any): any {
    var user = {
      ds_Login: data.login.toString(),
      ds_Senha: data.password
    };  

    return this.http
      .post<User>(
        this.pathAPI + 'authorization/getToken',
        JSON.stringify(user),
        this.header()
      )
      .pipe(retry(0), catchError(this.handleError));
  }

  public setToken(data: any) {
    super.setToken(data);
  }

  public auth(data: any): any {
    let body = JSON.stringify(data);
    this.getToken(body).subscribe((token) => {
      return token;
    });;
  }

  private getToken(body: any): Observable<string> {
    return this.http.post<any>(this.pathAPI + 'authorization/getToken', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
